import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, Timestamp } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { BarChart, Calendar, DollarSign, Users } from 'lucide-react';

interface BusinessAnalyticsProps {
  businessId: string;
  startDate: Date;
  endDate: Date;
}

interface AnalyticsData {
  totalBookings: number;
  totalRevenue: number;
  popularServices: Array<{ name: string; count: number }>;
  peakHours: Array<{ hour: number; count: number }>;
  staffUtilization: Array<{ name: string; bookings: number }>;
}

export default function BusinessAnalytics({ businessId, startDate, endDate }: BusinessAnalyticsProps) {
  const [analytics, setAnalytics] = useState<AnalyticsData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchAnalytics = async () => {
      try {
        setLoading(true);
        const appointmentsRef = collection(db, 'businesses', businessId, 'appointments');
        const q = query(
          appointmentsRef,
          where('date', '>=', Timestamp.fromDate(startDate)),
          where('date', '<=', Timestamp.fromDate(endDate))
        );

        const snapshot = await getDocs(q);
        const appointments = snapshot.docs.map(doc => ({
          ...doc.data(),
          date: doc.data().date.toDate()
        }));

        // Calculate analytics
        const data: AnalyticsData = {
          totalBookings: appointments.length,
          totalRevenue: appointments.reduce((sum, app) => sum + (app.price || 0), 0),
          popularServices: [],
          peakHours: [],
          staffUtilization: []
        };

        // Calculate popular services
        const serviceCount = appointments.reduce((acc, app) => {
          acc[app.service] = (acc[app.service] || 0) + 1;
          return acc;
        }, {} as Record<string, number>);

        data.popularServices = Object.entries(serviceCount)
          .map(([name, count]) => ({ name, count }))
          .sort((a, b) => b.count - a.count);

        // Calculate peak hours
        const hourCount = appointments.reduce((acc, app) => {
          const hour = app.date.getHours();
          acc[hour] = (acc[hour] || 0) + 1;
          return acc;
        }, {} as Record<number, number>);

        data.peakHours = Object.entries(hourCount)
          .map(([hour, count]) => ({ hour: parseInt(hour), count }))
          .sort((a, b) => b.count - a.count);

        // Calculate staff utilization
        const staffCount = appointments.reduce((acc, app) => {
          if (app.employeeId) {
            acc[app.employeeId] = (acc[app.employeeId] || 0) + 1;
          }
          return acc;
        }, {} as Record<string, number>);

        data.staffUtilization = Object.entries(staffCount)
          .map(([id, bookings]) => ({ name: id, bookings }))
          .sort((a, b) => b.bookings - a.bookings);

        setAnalytics(data);
      } catch (err) {
        console.error('Error fetching analytics:', err);
        setError(err instanceof Error ? err.message : 'Failed to fetch analytics');
      } finally {
        setLoading(false);
      }
    };

    fetchAnalytics();
  }, [businessId, startDate, endDate]);

  if (loading) {
    return (
      <div className="bg-white rounded-xl shadow-lg p-6">
        <div className="animate-pulse space-y-4">
          <div className="h-8 bg-slate-200 rounded w-1/4"></div>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
            {[...Array(4)].map((_, i) => (
              <div key={i} className="h-24 bg-slate-200 rounded"></div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-white rounded-xl shadow-lg p-6">
        <p className="text-red-500">{error}</p>
      </div>
    );
  }

  if (!analytics) return null;

  return (
    <div className="bg-white rounded-xl shadow-lg p-6">
      <h2 className="text-2xl font-semibold text-slate-900 mb-6">Business Analytics</h2>

      <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mb-8">
        <div className="bg-indigo-50 p-4 rounded-lg">
          <div className="flex items-center">
            <Calendar className="w-8 h-8 text-indigo-600" />
            <div className="ml-4">
              <p className="text-sm text-slate-600">Total Bookings</p>
              <p className="text-2xl font-semibold text-slate-900">{analytics.totalBookings}</p>
            </div>
          </div>
        </div>

        <div className="bg-green-50 p-4 rounded-lg">
          <div className="flex items-center">
            <DollarSign className="w-8 h-8 text-green-600" />
            <div className="ml-4">
              <p className="text-sm text-slate-600">Total Revenue</p>
              <p className="text-2xl font-semibold text-slate-900">
                ${analytics.totalRevenue.toFixed(2)}
              </p>
            </div>
          </div>
        </div>

        <div className="bg-blue-50 p-4 rounded-lg">
          <div className="flex items-center">
            <BarChart className="w-8 h-8 text-blue-600" />
            <div className="ml-4">
              <p className="text-sm text-slate-600">Peak Hour</p>
              <p className="text-2xl font-semibold text-slate-900">
                {analytics.peakHours[0]?.hour}:00
              </p>
            </div>
          </div>
        </div>

        <div className="bg-purple-50 p-4 rounded-lg">
          <div className="flex items-center">
            <Users className="w-8 h-8 text-purple-600" />
            <div className="ml-4">
              <p className="text-sm text-slate-600">Top Staff</p>
              <p className="text-2xl font-semibold text-slate-900">
                {analytics.staffUtilization[0]?.bookings || 0} bookings
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div>
          <h3 className="text-lg font-semibold text-slate-900 mb-4">Popular Services</h3>
          <div className="space-y-2">
            {analytics.popularServices.slice(0, 5).map((service, index) => (
              <div key={service.name} className="flex items-center justify-between">
                <span className="text-slate-600">{service.name}</span>
                <span className="text-slate-900 font-medium">{service.count} bookings</span>
              </div>
            ))}
          </div>
        </div>

        <div>
          <h3 className="text-lg font-semibold text-slate-900 mb-4">Peak Hours</h3>
          <div className="space-y-2">
            {analytics.peakHours.slice(0, 5).map((hour) => (
              <div key={hour.hour} className="flex items-center justify-between">
                <span className="text-slate-600">{hour.hour}:00 - {hour.hour + 1}:00</span>
                <span className="text-slate-900 font-medium">{hour.count} bookings</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}