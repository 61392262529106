import React, { useState } from 'react';
import { Plus, Save, Trash2, UserPlus, Clock } from 'lucide-react';
import { collection, query, where, getDocs, updateDoc, doc, addDoc, deleteDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import type { Employee, BusinessService } from '../../types/business';

interface EmployeeManagementProps {
  businessId: string;
  services: BusinessService[];
  employees: Employee[];
  onAdd: (employee: Omit<Employee, 'id' | 'createdAt' | 'updatedAt'>) => Promise<{ success: boolean; employee?: Employee; error?: any }>;
  onUpdate: (employeeId: string, updates: Partial<Employee>) => Promise<{ success: boolean; error?: any }>;
}

export default function EmployeeManagement({ 
  businessId, 
  services = [], 
  employees = [], 
  onAdd, 
  onUpdate 
}: EmployeeManagementProps) {
  const [showAddForm, setShowAddForm] = useState(false);
  const [editingEmployee, setEditingEmployee] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [newEmployee, setNewEmployee] = useState({
    name: '',
    email: '',
    phone: '',
    role: 'staff' as const,
    services: [] as string[],
    schedule: {
      monday: { start: '09:00', end: '17:00', enabled: true },
      tuesday: { start: '09:00', end: '17:00', enabled: true },
      wednesday: { start: '09:00', end: '17:00', enabled: true },
      thursday: { start: '09:00', end: '17:00', enabled: true },
      friday: { start: '09:00', end: '17:00', enabled: true },
      saturday: { start: '09:00', end: '17:00', enabled: true },
      sunday: { start: '09:00', end: '17:00', enabled: false }
    }
  });

  const handleServiceToggle = async (serviceId: string, employeeId?: string) => {
    try {
      if (employeeId) {
        const employee = employees.find(e => e.id === employeeId);
        if (employee) {
          const updatedServices = employee.services.includes(serviceId)
            ? employee.services.filter(id => id !== serviceId)
            : [...employee.services, serviceId];
          
          // Update employee services in Firestore
          const employeeRef = doc(db, 'businesses', businessId, 'employees', employeeId);
          await updateDoc(employeeRef, {
            services: updatedServices,
            updatedAt: serverTimestamp()
          });

          // Update service assignments
          const serviceAssignmentsRef = collection(db, 'businesses', businessId, 'serviceAssignments');
          
          if (employee.services.includes(serviceId)) {
            // Remove service assignment
            const q = query(
              serviceAssignmentsRef,
              where('employeeId', '==', employeeId),
              where('serviceId', '==', serviceId)
            );
            const snapshot = await getDocs(q);
            await Promise.all(snapshot.docs.map(doc => deleteDoc(doc.ref)));
          } else {
            // Add service assignment
            await addDoc(serviceAssignmentsRef, {
              employeeId,
              serviceId,
              createdAt: serverTimestamp()
            });
          }

          // Update local state through parent component
          await onUpdate(employeeId, { services: updatedServices });
        }
      } else {
        setNewEmployee(prev => ({
          ...prev,
          services: prev.services.includes(serviceId)
            ? prev.services.filter(id => id !== serviceId)
            : [...prev.services, serviceId]
        }));
      }
    } catch (error) {
      console.error('Error updating employee services:', error);
      setError('Failed to update services');
    }
  };

  const handleAddEmployee = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const result = await onAdd(newEmployee);
      if (result.success) {
        setSuccess('Employee added successfully!');
        setShowAddForm(false);
        setNewEmployee({
          name: '',
          email: '',
          phone: '',
          role: 'staff',
          services: [],
          schedule: {
            monday: { start: '09:00', end: '17:00', enabled: true },
            tuesday: { start: '09:00', end: '17:00', enabled: true },
            wednesday: { start: '09:00', end: '17:00', enabled: true },
            thursday: { start: '09:00', end: '17:00', enabled: true },
            friday: { start: '09:00', end: '17:00', enabled: true },
            saturday: { start: '09:00', end: '17:00', enabled: true },
            sunday: { start: '09:00', end: '17:00', enabled: false }
          }
        });
      } else {
        throw result.error;
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to add employee');
    } finally {
      setLoading(false);
    }
  };

  const handleScheduleUpdate = async (
    employeeId: string,
    day: string,
    field: 'start' | 'end' | 'enabled',
    value: string | boolean
  ) => {
    try {
      const employee = employees.find(e => e.id === employeeId);
      if (employee) {
        const updatedSchedule = {
          ...employee.schedule,
          [day]: {
            ...employee.schedule[day],
            [field]: value
          }
        };

        await onUpdate(employeeId, { schedule: updatedSchedule });
      }
    } catch (error) {
      console.error('Error updating schedule:', error);
      setError('Failed to update schedule');
    }
  };

  return (
    <div className="bg-white rounded-xl shadow-lg p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-semibold text-slate-900">Employees</h2>
        <button
          onClick={() => setShowAddForm(true)}
          className="flex items-center px-3 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
        >
          <UserPlus className="w-4 h-4 mr-2" />
          Add Employee
        </button>
      </div>

      {showAddForm && (
        <form onSubmit={handleAddEmployee} className="mb-8 p-4 border border-slate-200 rounded-lg">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div>
              <label className="block text-sm font-medium text-slate-700 mb-1">Name</label>
              <input
                type="text"
                value={newEmployee.name}
                onChange={(e) => setNewEmployee({ ...newEmployee, name: e.target.value })}
                className="w-full p-2 border border-slate-200 rounded-lg"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-slate-700 mb-1">Email</label>
              <input
                type="email"
                value={newEmployee.email}
                onChange={(e) => setNewEmployee({ ...newEmployee, email: e.target.value })}
                className="w-full p-2 border border-slate-200 rounded-lg"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-slate-700 mb-1">Phone</label>
              <input
                type="tel"
                value={newEmployee.phone}
                onChange={(e) => setNewEmployee({ ...newEmployee, phone: e.target.value })}
                className="w-full p-2 border border-slate-200 rounded-lg"
                required
              />
            </div>
          </div>

          <div className="mb-4">
            <h3 className="text-sm font-medium text-slate-700 mb-2">Services</h3>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
              {services.map((service) => (
                <label
                  key={service.id}
                  className="flex items-center p-2 border border-slate-200 rounded-lg cursor-pointer hover:bg-slate-50"
                >
                  <input
                    type="checkbox"
                    checked={newEmployee.services.includes(service.id)}
                    onChange={() => handleServiceToggle(service.id)}
                    className="mr-2"
                  />
                  <span className="text-sm text-slate-700">{service.name}</span>
                </label>
              ))}
            </div>
          </div>

          <div className="mb-4">
            <h3 className="text-sm font-medium text-slate-700 mb-2">Schedule</h3>
            <div className="space-y-2">
              {Object.entries(newEmployee.schedule).map(([day, hours]) => (
                <div key={day} className="flex items-center space-x-4">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      checked={hours.enabled}
                      onChange={(e) => setNewEmployee(prev => ({
                        ...prev,
                        schedule: {
                          ...prev.schedule,
                          [day]: { ...hours, enabled: e.target.checked }
                        }
                      }))}
                      className="mr-2"
                    />
                    <span className="text-sm text-slate-700 capitalize">{day}</span>
                  </label>
                  <input
                    type="time"
                    value={hours.start}
                    onChange={(e) => setNewEmployee(prev => ({
                      ...prev,
                      schedule: {
                        ...prev.schedule,
                        [day]: { ...hours, start: e.target.value }
                      }
                    }))}
                    className="p-1 border border-slate-200 rounded"
                    disabled={!hours.enabled}
                  />
                  <span className="text-sm text-slate-600">to</span>
                  <input
                    type="time"
                    value={hours.end}
                    onChange={(e) => setNewEmployee(prev => ({
                      ...prev,
                      schedule: {
                        ...prev.schedule,
                        [day]: { ...hours, end: e.target.value }
                      }
                    }))}
                    className="p-1 border border-slate-200 rounded"
                    disabled={!hours.enabled}
                  />
                </div>
              ))}
            </div>
          </div>

          {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
          {success && <p className="text-green-500 text-sm mb-4">{success}</p>}

          <div className="flex justify-end space-x-2">
            <button
              type="button"
              onClick={() => setShowAddForm(false)}
              className="px-4 py-2 text-slate-600 hover:text-slate-900"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={loading}
              className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 disabled:opacity-50"
            >
              {loading ? 'Adding...' : 'Add Employee'}
            </button>
          </div>
        </form>
      )}

      <div className="space-y-4">
        {employees.map((employee) => (
          <div key={employee.id} className="p-4 border border-slate-200 rounded-lg">
            <div className="flex justify-between items-start">
              <div>
                <h3 className="font-semibold text-slate-900">{employee.name}</h3>
                <p className="text-slate-600">{employee.email}</p>
                <p className="text-slate-600">{employee.phone}</p>
              </div>
              <button
                onClick={() => setEditingEmployee(editingEmployee === employee.id ? null : employee.id)}
                className="p-2 text-slate-400 hover:text-slate-600"
              >
                {editingEmployee === employee.id ? <Save className="w-5 h-5" /> : <Clock className="w-5 h-5" />}
              </button>
            </div>

            {editingEmployee === employee.id && (
              <div className="mt-4">
                <h4 className="text-sm font-medium text-slate-700 mb-2">Services</h4>
                <div className="grid grid-cols-2 md:grid-cols-3 gap-2 mb-4">
                  {services.map((service) => (
                    <label
                      key={service.id}
                      className="flex items-center p-2 border border-slate-200 rounded-lg cursor-pointer hover:bg-slate-50"
                    >
                      <input
                        type="checkbox"
                        checked={employee.services.includes(service.id)}
                        onChange={() => handleServiceToggle(service.id, employee.id)}
                        className="mr-2"
                      />
                      <span className="text-sm text-slate-700">{service.name}</span>
                    </label>
                  ))}
                </div>

                <h4 className="text-sm font-medium text-slate-700 mb-2">Schedule</h4>
                <div className="space-y-2">
                  {Object.entries(employee.schedule).map(([day, hours]) => (
                    <div key={day} className="flex items-center space-x-4">
                      <label className="flex items-center">
                        <input
                          type="checkbox"
                          checked={hours.enabled}
                          onChange={(e) => handleScheduleUpdate(employee.id, day, 'enabled', e.target.checked)}
                          className="mr-2"
                        />
                        <span className="text-sm text-slate-700 capitalize">{day}</span>
                      </label>
                      <input
                        type="time"
                        value={hours.start}
                        onChange={(e) => handleScheduleUpdate(employee.id, day, 'start', e.target.value)}
                        className="p-1 border border-slate-200 rounded"
                        disabled={!hours.enabled}
                      />
                      <span className="text-sm text-slate-600">to</span>
                      <input
                        type="time"
                        value={hours.end}
                        onChange={(e) => handleScheduleUpdate(employee.id, day, 'end', e.target.value)}
                        className="p-1 border border-slate-200 rounded"
                        disabled={!hours.enabled}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        ))}

        {employees.length === 0 && !showAddForm && (
          <p className="text-center text-slate-600 py-4">No employees added yet</p>
        )}
      </div>
    </div>
  );
}