import React, { useState, useEffect } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { Link } from 'react-router-dom';
import { Calendar, Clock, MapPin } from 'lucide-react';

interface BusinessAppointment {
  id: string;
  businessId: string;
  businessName: string;
  service: string;
  date: Date;
  status: 'pending' | 'confirmed' | 'cancelled';
}

export default function CustomerPortal() {
  const { user } = useAuth();
  const [appointments, setAppointments] = useState<BusinessAppointment[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAppointments = async () => {
      if (!user) return;

      try {
        const appointmentsQuery = query(
          collection(db, 'appointments'),
          where('customerId', '==', user.uid)
        );
        
        const snapshot = await getDocs(appointmentsQuery);
        const appointmentsData = await Promise.all(
          snapshot.docs.map(async (doc) => {
            const data = doc.data();
            const businessDoc = await getDocs(
              query(collection(db, 'businesses'), 
              where('id', '==', data.businessId))
            );
            const businessData = businessDoc.docs[0]?.data();
            
            return {
              id: doc.id,
              businessId: data.businessId,
              businessName: businessData?.name || 'Unknown Business',
              service: data.service,
              date: data.date.toDate(),
              status: data.status,
            };
          })
        );

        setAppointments(appointmentsData);
      } catch (error) {
        console.error('Error fetching appointments:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAppointments();
  }, [user]);

  return (
    <div className="pt-20 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-3xl font-bold text-slate-900 mb-8">My Appointments</h1>

        {loading ? (
          <div className="text-center py-8">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600 mx-auto"></div>
          </div>
        ) : appointments.length > 0 ? (
          <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
            {appointments.map((appointment) => (
              <div
                key={appointment.id}
                className="bg-white rounded-lg shadow-md p-6 space-y-4"
              >
                <div className="flex items-start justify-between">
                  <div>
                    <h3 className="font-semibold text-lg text-slate-900">
                      {appointment.businessName}
                    </h3>
                    <p className="text-slate-600">{appointment.service}</p>
                  </div>
                  <span className={`px-3 py-1 rounded-full text-sm font-medium
                    ${appointment.status === 'confirmed' ? 'bg-green-100 text-green-800' :
                      appointment.status === 'pending' ? 'bg-yellow-100 text-yellow-800' :
                      'bg-red-100 text-red-800'}`}>
                    {appointment.status.charAt(0).toUpperCase() + appointment.status.slice(1)}
                  </span>
                </div>

                <div className="space-y-2">
                  <div className="flex items-center text-slate-600">
                    <Calendar className="w-4 h-4 mr-2" />
                    {appointment.date.toLocaleDateString()}
                  </div>
                  <div className="flex items-center text-slate-600">
                    <Clock className="w-4 h-4 mr-2" />
                    {appointment.date.toLocaleTimeString()}
                  </div>
                </div>

                <Link
                  to={`/b/${appointment.businessId}`}
                  className="block mt-4 text-indigo-600 hover:text-indigo-700 font-medium"
                >
                  View Business
                </Link>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center py-8">
            <p className="text-slate-600">No appointments found.</p>
            <p className="mt-2">
              <Link to="/" className="text-indigo-600 hover:text-indigo-700 font-medium">
                Find businesses and book appointments
              </Link>
            </p>
          </div>
        )}
      </div>
    </div>
  );
}