import React from 'react';
import { useAuth } from '../../hooks/useAuth';
import { useBusinessManagement } from '../../hooks/useBusinessManagement';
import BusinessProfile from './BusinessProfile';
import EmployeeManagement from './EmployeeManagement';
import ServicesManagement from './ServicesManagement';
import BusinessCalendar from './BusinessCalendar';
import BusinessAnalytics from './BusinessAnalytics';
import { Loader } from 'lucide-react';
import { Navigate } from 'react-router-dom';
import { doc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../lib/firebase';

export default function BusinessPortal() {
  const { user, userRole } = useAuth();
  const { 
    business,
    employees,
    loading,
    error,
    addEmployee,
    updateEmployee
  } = useBusinessManagement(user?.uid || '');

  // Redirect if not a business user
  if (userRole && userRole !== 'business') {
    return <Navigate to="/" replace />;
  }

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <Loader className="w-8 h-8 text-indigo-600 animate-spin" />
      </div>
    );
  }

  if (error || !business) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-slate-900 mb-2">Error</h2>
          <p className="text-slate-600">{error?.message || 'Business not found'}</p>
        </div>
      </div>
    );
  }

  const handleUpdateServices = async (services: any) => {
    try {
      const businessRef = doc(db, 'businesses', business.id);
      await updateDoc(businessRef, {
        'settings.services': services,
        updatedAt: serverTimestamp()
      });
      return { success: true };
    } catch (err) {
      console.error('Error updating services:', err);
      return { success: false, error: err };
    }
  };

  return (
    <div className="min-h-screen bg-slate-50 pt-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Business Info Header */}
        <div className="bg-white rounded-xl shadow-lg p-6 mb-8">
          <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4">
            <div>
              <h1 className="text-2xl font-bold text-slate-900">{business.name}</h1>
              <p className="text-slate-600">{business.contact.email}</p>
            </div>
            <div className="flex flex-col items-end">
              <div className="flex items-center space-x-2">
                <span className="text-sm text-slate-600">Subscription Plan:</span>
                <span className="font-medium text-slate-900 capitalize">
                  {business.subscription.plan}
                </span>
              </div>
              <div className="flex items-center space-x-2 mt-1">
                <span className="text-sm text-slate-600">Status:</span>
                <span className={`px-2 py-1 rounded-full text-xs font-medium ${
                  business.subscription.status === 'active'
                    ? 'bg-green-100 text-green-800'
                    : 'bg-red-100 text-red-800'
                }`}>
                  {business.subscription.status.charAt(0).toUpperCase() + 
                   business.subscription.status.slice(1)}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-8">
          {/* Business Profile */}
          <BusinessProfile business={business} />
          
          {/* Business Analytics */}
          <BusinessAnalytics 
            businessId={business.id}
            startDate={new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)}
            endDate={new Date()}
          />
          
          {/* Calendar */}
          <BusinessCalendar
            businessId={business.id}
            employees={employees}
            services={business.settings.services}
            workingHours={business.settings.workingHours}
          />
          
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            {/* Services Management */}
            <ServicesManagement
              businessId={business.id}
              services={business.settings.services}
              onUpdate={handleUpdateServices}
            />
            
            {/* Employee Management */}
            <EmployeeManagement
              businessId={business.id}
              services={business.settings.services}
              employees={employees}
              onAdd={addEmployee}
              onUpdate={updateEmployee}
            />
          </div>
        </div>
      </div>
    </div>
  );
}