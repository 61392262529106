import { initializeApp } from 'firebase/app';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { getFirestore, doc, setDoc, serverTimestamp } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyDX-nW1xlmwh1r78MpZL_mL-82StSmh930",
  authDomain: "slotwise-c97b3.firebaseapp.com",
  projectId: "slotwise-c97b3",
  storageBucket: "slotwise-c97b3.appspot.com",
  messagingSenderId: "841226965296",
  appId: "1:841226965296:web:d36ff84dee6d695947b8f5"
};

// Initialize Firebase services
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

// Enable offline persistence
import { enableIndexedDbPersistence } from 'firebase/firestore';

try {
  enableIndexedDbPersistence(db);
} catch (err) {
  if (err.code == 'failed-precondition') {
    // Multiple tabs open, persistence can only be enabled in one tab at a time
    console.warn('Firebase persistence has already been enabled in another tab');
  } else if (err.code == 'unimplemented') {
    // The current browser doesn't support persistence
    console.warn('Firebase persistence is not supported in this browser');
  }
}

// Helper function to upload a file to storage
export const uploadFile = async (file: File, path: string) => {
  try {
    const storageRef = ref(storage, path);
    const snapshot = await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(snapshot.ref);
    return { success: true, url: downloadURL };
  } catch (error) {
    console.error('Error uploading file:', error);
    return { success: false, error: 'Failed to upload file' };
  }
};

// Helper function to create a new user with email/password
export const createNewUser = async (email: string, password: string, userData: any) => {
  try {
    // Create the user in Firebase Auth
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    // Create the user document in Firestore
    const userRef = doc(db, 'users', user.uid);
    await setDoc(userRef, {
      ...userData,
      email: user.email,
      uid: user.uid,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp()
    });

    // If this is a business user, create their business profile
    if (userData.role === 'business') {
      const businessRef = doc(db, 'businesses', user.uid);
      await setDoc(businessRef, {
        id: user.uid,
        name: userData.businessName || `${userData.firstName}'s Business`,
        ownerId: user.uid,
        logo: '',
        contact: {
          email: user.email,
          phone: '',
          address: {
            street: '',
            city: '',
            state: '',
            zip: '',
            country: ''
          }
        },
        settings: {
          timezone: 'UTC',
          workingHours: {
            monday: { start: '09:00', end: '17:00', enabled: true },
            tuesday: { start: '09:00', end: '17:00', enabled: true },
            wednesday: { start: '09:00', end: '17:00', enabled: true },
            thursday: { start: '09:00', end: '17:00', enabled: true },
            friday: { start: '09:00', end: '17:00', enabled: true },
            saturday: { start: '09:00', end: '17:00', enabled: true },
            sunday: { start: '09:00', end: '17:00', enabled: false }
          },
          services: []
        },
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      });
    }

    return { success: true, user };
  } catch (error: any) {
    console.error('Error creating new user:', error);
    let errorMessage = 'Failed to create account';
    
    if (error.code === 'auth/email-already-in-use') {
      errorMessage = 'Email already in use';
    } else if (error.code === 'auth/invalid-email') {
      errorMessage = 'Invalid email address';
    } else if (error.code === 'auth/operation-not-allowed') {
      errorMessage = 'Email/password accounts are not enabled. Please contact support.';
    } else if (error.code === 'auth/weak-password') {
      errorMessage = 'Password should be at least 6 characters';
    }
    
    return { success: false, error: errorMessage };
  }
};

// Helper function to sign in a user with email/password
export const signInUser = async (email: string, password: string) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    return { success: true, user: userCredential.user };
  } catch (error: any) {
    console.error('Error signing in:', error);
    let errorMessage = 'Failed to sign in';
    
    if (error.code === 'auth/user-not-found' || error.code === 'auth/wrong-password') {
      errorMessage = 'Invalid email or password';
    } else if (error.code === 'auth/invalid-email') {
      errorMessage = 'Invalid email address';
    } else if (error.code === 'auth/user-disabled') {
      errorMessage = 'This account has been disabled';
    }
    
    return { success: false, error: errorMessage };
  }
};

export { app, auth, db, storage };