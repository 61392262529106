import React, { useState } from 'react';
import { Check } from 'lucide-react';
import { useAuth } from '../hooks/useAuth';
import AuthModal from './AuthModal';
import CheckoutModal from './checkout/CheckoutModal';

interface Plan {
  name: string;
  price: number;
  monthlyPayPalPlanId: string;
  features: string[];
  isPopular?: boolean;
}

const plans: Plan[] = [
  {
    name: 'Basic',
    price: 19.99,
    monthlyPayPalPlanId: import.meta.env.VITE_PAYPAL_PLAN_BASIC_MONTHLY,
    features: [
      '100 bookings per month',
      'Up to 5 staff members',
      'Basic analytics',
      'Email notifications',
      'Online booking widget'
    ]
  },
  {
    name: 'Standard',
    price: 49.99,
    isPopular: true,
    monthlyPayPalPlanId: import.meta.env.VITE_PAYPAL_PLAN_STANDARD_MONTHLY,
    features: [
      'Unlimited bookings',
      'Up to 15 staff members',
      'Advanced analytics',
      'SMS notifications',
      'Custom booking page',
      'Integration with Google Calendar',
      'Priority support'
    ]
  },
  {
    name: 'Premium',
    price: 99.99,
    monthlyPayPalPlanId: import.meta.env.VITE_PAYPAL_PLAN_PREMIUM_MONTHLY,
    features: [
      'Everything in Standard',
      'Up to 50 staff members',
      'White-label solution',
      'API access',
      'Dedicated account manager',
      'Custom integration support',
      '24/7 phone support'
    ]
  }
];

export default function Pricing() {
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [showCheckoutModal, setShowCheckoutModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<Plan | null>(null);
  const { user } = useAuth();

  const handlePlanSelection = (plan: Plan) => {
    if (!user) {
      setSelectedPlan(plan);
      setShowAuthModal(true);
    } else {
      setSelectedPlan(plan);
      setShowCheckoutModal(true);
    }
  };

  return (
    <div className="py-24 bg-slate-50" id="pricing">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-bold text-slate-900">Simple, transparent pricing</h2>
          <p className="mt-4 text-lg text-slate-600">
            Choose the perfect plan for your business
          </p>
        </div>

        {/* Pricing Cards */}
        <div className="mt-12 grid grid-cols-1 gap-8 lg:grid-cols-3">
          {plans.map((plan) => (
            <div
              key={plan.name}
              className={`relative flex flex-col rounded-2xl ${
                plan.isPopular
                  ? 'bg-indigo-600 text-white shadow-xl scale-105 z-10'
                  : 'bg-white text-slate-900 shadow-lg'
              }`}
            >
              {plan.isPopular && (
                <div className="absolute -top-4 left-1/2 -translate-x-1/2">
                  <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-semibold bg-indigo-100 text-indigo-600">
                    Popular
                  </span>
                </div>
              )}

              <div className="p-8">
                <h3 className="text-2xl font-bold">{plan.name}</h3>
                <div className="mt-4 flex items-baseline">
                  <span className="text-4xl font-bold">
                    ${plan.price}
                  </span>
                  <span className="ml-2 text-sm opacity-80">/month</span>
                </div>

                <ul className="mt-8 space-y-4">
                  {plan.features.map((feature, index) => (
                    <li key={index} className="flex items-center">
                      <Check className={`w-5 h-5 ${
                        plan.isPopular ? 'text-indigo-200' : 'text-indigo-600'
                      } mr-3`} />
                      <span>{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="mt-auto p-8 pt-0">
                <button
                  onClick={() => handlePlanSelection(plan)}
                  className={`w-full rounded-lg px-4 py-2 font-medium transition-colors ${
                    plan.isPopular
                      ? 'bg-white text-indigo-600 hover:bg-indigo-50'
                      : 'bg-indigo-600 text-white hover:bg-indigo-700'
                  }`}
                >
                  Subscribe Now
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      <AuthModal
        isOpen={showAuthModal}
        onClose={() => {
          setShowAuthModal(false);
          setSelectedPlan(null);
        }}
        mode="signup"
      />

      {selectedPlan && (
        <CheckoutModal
          isOpen={showCheckoutModal}
          onClose={() => {
            setShowCheckoutModal(false);
            setSelectedPlan(null);
          }}
          plan={selectedPlan}
          isAnnual={false}
        />
      )}
    </div>
  );
}