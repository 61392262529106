import React from 'react';
import Hero from '../components/Hero';
import Features from '../components/Features';
import BookingDemo from '../components/BookingDemo';
import About from '../components/About';
import Pricing from '../components/Pricing';
import Footer from '../components/Footer';

export default function HomePage() {
  return (
    <div className="flex flex-col min-h-screen">
      <main className="flex-grow">
        <Hero />
        <Features />
        <BookingDemo />
        <About />
        <Pricing />
      </main>
      <Footer />
    </div>
  );
}