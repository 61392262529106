import React, { useState, useEffect } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { useBookings } from '../../hooks/useBookings';
import { Business, Employee } from '../../types/business';
import AuthModal from '../AuthModal';
import { Calendar, Clock, User, Phone, AlertCircle, Loader } from 'lucide-react';
import { Link } from 'react-router-dom';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../lib/firebase';

interface BookingFormProps {
  business: Business;
  employees: Employee[];
  monthlyBookings: number;
}

export default function BookingForm({ business, employees, monthlyBookings }: BookingFormProps) {
  const { user } = useAuth();
  const { createBooking } = useBookings(user?.uid);
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTime, setSelectedTime] = useState('');
  const [selectedService, setSelectedService] = useState('');
  const [selectedEmployee, setSelectedEmployee] = useState('');
  const [customerPhone, setCustomerPhone] = useState('');
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checkingAvailability, setCheckingAvailability] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [availableTimeSlots, setAvailableTimeSlots] = useState<string[]>([]);
  const [availableEmployees, setAvailableEmployees] = useState<Employee[]>([]);

  // Update available employees when service or date/time changes
  useEffect(() => {
    const updateAvailableEmployees = async () => {
      if (!selectedService) {
        setAvailableEmployees([]);
        return;
      }

      // First filter by service capability
      const employeesWithService = employees.filter(employee =>
        employee.services.includes(selectedService)
      );

      if (!selectedDate || !selectedTime) {
        setAvailableEmployees(employeesWithService);
        return;
      }

      setCheckingAvailability(true);
      try {
        // Then check availability for the selected time
        const service = business.settings.services.find(s => s.id === selectedService);
        if (!service) throw new Error('Service not found');

        const appointmentDate = new Date(`${selectedDate}T${selectedTime}`);
        const availableStaff: Employee[] = [];

        for (const employee of employeesWithService) {
          const dayOfWeek = appointmentDate.toLocaleDateString('en-US', { weekday: 'long' }).toLowerCase();
          const schedule = employee.schedule[dayOfWeek];

          // Skip if day is not enabled
          if (!schedule.enabled) continue;

          // Check if time is within working hours
          const [startHour, startMinute] = schedule.start.split(':').map(Number);
          const [endHour, endMinute] = schedule.end.split(':').map(Number);
          
          const workStart = new Date(appointmentDate);
          workStart.setHours(startHour, startMinute, 0, 0);
          
          const workEnd = new Date(appointmentDate);
          workEnd.setHours(endHour, endMinute, 0, 0);

          const appointmentEnd = new Date(appointmentDate.getTime() + service.duration * 60000);

          // Skip if appointment is outside working hours
          if (appointmentDate < workStart || appointmentEnd > workEnd) continue;

          // Check for existing appointments
          const appointmentsRef = collection(db, 'businesses', business.id, 'appointments');
          const q = query(
            appointmentsRef,
            where('employeeId', '==', employee.id),
            where('date', '>=', appointmentDate),
            where('date', '<=', appointmentEnd)
          );

          const snapshot = await getDocs(q);
          if (snapshot.empty) {
            availableStaff.push(employee);
          }
        }

        setAvailableEmployees(availableStaff);

        // Clear selected employee if they're no longer available
        if (selectedEmployee && !availableStaff.some(e => e.id === selectedEmployee)) {
          setSelectedEmployee('');
        }
      } catch (err) {
        console.error('Error checking employee availability:', err);
        setError('Failed to check staff availability');
      } finally {
        setCheckingAvailability(false);
      }
    };

    updateAvailableEmployees();
  }, [selectedService, selectedDate, selectedTime, business.id, employees]);

  useEffect(() => {
    if (selectedDate && selectedEmployee && selectedService) {
      fetchAvailableTimeSlots();
    } else {
      setAvailableTimeSlots([]);
    }
  }, [selectedDate, selectedEmployee, selectedService]);

  const fetchAvailableTimeSlots = async () => {
    try {
      // Get employee's working hours for the selected date
      const employee = employees.find(e => e.id === selectedEmployee);
      if (!employee) return;

      const dayOfWeek = new Date(selectedDate)
        .toLocaleDateString('en-US', { weekday: 'long' })
        .toLowerCase();
      
      const workingHours = employee.schedule[dayOfWeek];
      if (!workingHours || !workingHours.enabled) {
        setAvailableTimeSlots([]);
        return;
      }

      // Get selected service duration
      const service = business.settings.services.find(s => s.id === selectedService);
      if (!service) return;
      const serviceDuration = service.duration;

      // Fetch existing appointments for the employee on the selected date
      const startOfDay = new Date(selectedDate);
      startOfDay.setHours(0, 0, 0, 0);
      const endOfDay = new Date(selectedDate);
      endOfDay.setHours(23, 59, 59, 999);

      const appointmentsRef = collection(db, 'businesses', business.id, 'appointments');
      const q = query(
        appointmentsRef,
        where('employeeId', '==', selectedEmployee),
        where('date', '>=', startOfDay),
        where('date', '<=', endOfDay)
      );
      
      const snapshot = await getDocs(q);
      const bookedSlots = snapshot.docs.map(doc => {
        const data = doc.data();
        const date = data.date.toDate();
        return {
          startTime: date.getHours(),
          startMinute: date.getMinutes(),
          duration: data.duration
        };
      });

      // Generate available time slots
      const slots: string[] = [];
      const [startHour, startMinute] = workingHours.start.split(':').map(Number);
      const [endHour, endMinute] = workingHours.end.split(':').map(Number);
      const now = new Date();
      const isToday = new Date(selectedDate).toDateString() === now.toDateString();

      for (let hour = startHour; hour <= endHour; hour++) {
        for (let minute = hour === startHour ? startMinute : 0; 
             minute < (hour === endHour ? endMinute : 60); 
             minute += 30) {
          const slotStart = new Date(selectedDate);
          slotStart.setHours(hour, minute, 0, 0);

          // Skip slots in the past
          if (isToday && slotStart < now) continue;

          const slotEnd = new Date(slotStart);
          slotEnd.setMinutes(slotStart.getMinutes() + serviceDuration);

          // Skip if slot ends after working hours
          if (slotEnd.getHours() > endHour || 
              (slotEnd.getHours() === endHour && slotEnd.getMinutes() > endMinute)) {
            continue;
          }

          // Check if the slot overlaps with any booked appointments
          const isSlotAvailable = !bookedSlots.some(booking => {
            const bookingStart = new Date(selectedDate);
            bookingStart.setHours(booking.startTime, booking.startMinute, 0, 0);
            const bookingEnd = new Date(bookingStart);
            bookingEnd.setMinutes(bookingStart.getMinutes() + booking.duration);

            return (
              (slotStart >= bookingStart && slotStart < bookingEnd) ||
              (slotEnd > bookingStart && slotEnd <= bookingEnd) ||
              (slotStart <= bookingStart && slotEnd >= bookingEnd)
            );
          });

          if (isSlotAvailable) {
            slots.push(
              slotStart.toLocaleTimeString('en-US', {
                hour: '2-digit',
                minute: '2-digit',
                hour12: true
              })
            );
          }
        }
      }

      setAvailableTimeSlots(slots);
    } catch (error) {
      console.error('Error fetching available time slots:', error);
      setError('Failed to fetch available time slots');
    }
  };

  const validateBooking = (): boolean => {
    if (!selectedService) {
      setError('Please select a service');
      return false;
    }

    if (!selectedEmployee) {
      setError('Please select a staff member');
      return false;
    }

    const employee = employees.find(e => e.id === selectedEmployee);
    if (!employee) {
      setError('Selected staff member not found');
      return false;
    }

    if (!employee.services.includes(selectedService)) {
      setError('Selected staff member does not provide this service');
      return false;
    }

    if (!selectedDate) {
      setError('Please select a date');
      return false;
    }

    if (!selectedTime) {
      setError('Please select a time slot');
      return false;
    }

    if (!customerPhone) {
      setError('Please provide your phone number');
      return false;
    }

    return true;
  };

  const handleBooking = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!user) {
      setShowAuthModal(true);
      return;
    }

    if (!validateBooking()) {
      return;
    }

    // Check monthly booking limits based on subscription
    if (monthlyBookings >= 50) {
      setError(
        'You have reached your monthly booking limit. Please upgrade your plan to make more bookings.'
      );
      return;
    }

    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const service = business.settings.services.find(s => s.id === selectedService);
      if (!service) throw new Error('Selected service not found');

      const appointmentDate = new Date(`${selectedDate}T${selectedTime}`);

      const result = await createBooking({
        userId: user.uid,
        businessId: business.id,
        service: service.name,
        serviceId: service.id,
        date: appointmentDate,
        time: selectedTime,
        employeeId: selectedEmployee,
        status: 'pending',
        duration: service.duration,
        price: service.price,
        customerPhone
      });

      if (result.success) {
        setSuccess('Booking created successfully! You will receive a confirmation email shortly.');
        setSelectedDate('');
        setSelectedTime('');
        setSelectedService('');
        setSelectedEmployee('');
        setCustomerPhone('');
      } else {
        throw result.error;
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to create booking');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <h2 className="text-xl font-semibold text-slate-900 mb-6">Book an Appointment</h2>

      {monthlyBookings >= 50 && (
        <div className="mb-6 p-4 bg-indigo-50 rounded-lg">
          <p className="text-indigo-700">
            You've reached your monthly booking limit. 
            <Link to="/pricing" className="font-medium ml-1 underline">
              Upgrade your plan
            </Link>
          </p>
        </div>
      )}

      <form onSubmit={handleBooking} className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-slate-700 mb-2">
            Select Service
          </label>
          <select
            value={selectedService}
            onChange={(e) => {
              setSelectedService(e.target.value);
              setSelectedEmployee('');
              setSelectedTime('');
            }}
            className="w-full p-3 border border-slate-200 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
            required
          >
            <option value="">Select a service</option>
            {business.settings.services.map((service) => (
              <option key={service.id} value={service.id}>
                {service.name} - ${service.price} ({service.duration} min)
              </option>
            ))}
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-slate-700 mb-2">
            Select Date
          </label>
          <input
            type="date"
            value={selectedDate}
            onChange={(e) => {
              setSelectedDate(e.target.value);
              setSelectedTime('');
              setSelectedEmployee('');
            }}
            min={new Date().toISOString().split('T')[0]}
            className="w-full p-3 border border-slate-200 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
            required
          />
        </div>

        {selectedDate && selectedService && (
          <div>
            <label className="block text-sm font-medium text-slate-700 mb-2">
              Available Times
            </label>
            <div className="grid grid-cols-3 gap-2">
              {availableTimeSlots.map((time) => (
                <button
                  key={time}
                  type="button"
                  onClick={() => setSelectedTime(time)}
                  className={`p-2 text-sm rounded-lg border transition-all ${
                    selectedTime === time
                      ? 'bg-indigo-600 text-white border-indigo-600'
                      : 'border-slate-200 hover:border-indigo-600 text-slate-700'
                  }`}
                >
                  {time}
                </button>
              ))}
              {availableTimeSlots.length === 0 && (
                <p className="col-span-3 text-slate-500 text-center py-2">
                  No available time slots for this date
                </p>
              )}
            </div>
          </div>
        )}

        {selectedService && (
          <div>
            <label className="block text-sm font-medium text-slate-700 mb-2">
              Select Staff Member
            </label>
            {checkingAvailability ? (
              <div className="flex items-center justify-center p-4">
                <Loader className="w-6 h-6 text-indigo-600 animate-spin" />
                <span className="ml-2 text-slate-600">Checking availability...</span>
              </div>
            ) : availableEmployees.length > 0 ? (
              <select
                value={selectedEmployee}
                onChange={(e) => {
                  setSelectedEmployee(e.target.value);
                  setSelectedTime('');
                }}
                className="w-full p-3 border border-slate-200 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                required
              >
                <option value="">Select a staff member</option>
                {availableEmployees.map((employee) => (
                  <option key={employee.id} value={employee.id}>
                    {employee.name}
                  </option>
                ))}
              </select>
            ) : (
              <div className="p-3 bg-yellow-50 border border-yellow-200 rounded-lg text-yellow-800 flex items-center gap-2">
                <AlertCircle className="w-5 h-5" />
                <span>No staff members available for this service at the selected time</span>
              </div>
            )}
          </div>
        )}

        <div>
          <label className="block text-sm font-medium text-slate-700 mb-2">
            Phone Number
          </label>
          <input
            type="tel"
            value={customerPhone}
            onChange={(e) => setCustomerPhone(e.target.value)}
            placeholder="+1 (555) 000-0000"
            className="w-full p-3 border border-slate-200 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
            required
          />
        </div>

        {error && (
          <div className="p-3 bg-red-50 border border-red-200 rounded-lg text-red-800 flex items-center gap-2">
            <AlertCircle className="w-5 h-5" />
            <p>{error}</p>
          </div>
        )}

        {success && (
          <div className="p-3 bg-green-50 border border-green-200 rounded-lg text-green-800">
            {success}
          </div>
        )}

        <button
          type="submit"
          disabled={loading || monthlyBookings >= 50}
          className="w-full bg-indigo-600 text-white py-3 rounded-lg hover:bg-indigo-700 transition-colors disabled:opacity-50"
        >
          {loading ? 'Creating Booking...' : user ? 'Confirm Booking' : 'Sign in to Book'}
        </button>
      </form>

      <AuthModal
        isOpen={showAuthModal}
        onClose={() => setShowAuthModal(false)}
        mode="signup"
      />
    </div>
  );
}