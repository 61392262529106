import React, { useState } from 'react';
import { Plus, Save, Trash2 } from 'lucide-react';
import type { BusinessService } from '../../types/business';

interface ServicesManagementProps {
  services: BusinessService[];
  onUpdate: (services: BusinessService[]) => Promise<{ success: boolean; error?: any }>;
}

export default function ServicesManagement({ services, onUpdate }: ServicesManagementProps) {
  const [editedServices, setEditedServices] = useState<BusinessService[]>(services);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  const addService = () => {
    const newService: BusinessService = {
      id: crypto.randomUUID(),
      name: '',
      duration: 30,
      price: 0,
      description: ''
    };
    setEditedServices([...editedServices, newService]);
  };

  const updateService = (index: number, updates: Partial<BusinessService>) => {
    const newServices = [...editedServices];
    
    // Handle price updates specifically to avoid NaN
    if ('price' in updates) {
      const priceValue = parseFloat(updates.price as any);
      updates.price = isNaN(priceValue) ? 0 : priceValue;
    }
    
    // Handle duration updates
    if ('duration' in updates) {
      const durationValue = parseInt(updates.duration as any);
      updates.duration = isNaN(durationValue) ? 30 : durationValue;
    }

    newServices[index] = { ...newServices[index], ...updates };
    setEditedServices(newServices);
  };

  const removeService = (index: number) => {
    setEditedServices(editedServices.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const result = await onUpdate(editedServices);
      if (result.success) {
        setSuccess('Services updated successfully!');
      } else {
        throw result.error;
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to update services');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-white rounded-xl shadow-lg p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-semibold text-slate-900">Services</h2>
        <button
          onClick={addService}
          className="flex items-center px-3 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
        >
          <Plus className="w-4 h-4 mr-2" />
          Add Service
        </button>
      </div>

      <form onSubmit={handleSubmit} className="space-y-6">
        {editedServices.map((service, index) => (
          <div key={service.id} className="p-4 border border-slate-200 rounded-lg space-y-4">
            <div className="flex justify-between">
              <div className="flex-1 grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-slate-700 mb-1">
                    Service Name
                  </label>
                  <input
                    type="text"
                    value={service.name}
                    onChange={(e) => updateService(index, { name: e.target.value })}
                    className="w-full p-2 border border-slate-200 rounded-lg"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-slate-700 mb-1">
                    Duration (minutes)
                  </label>
                  <input
                    type="number"
                    value={service.duration.toString()}
                    onChange={(e) => updateService(index, { duration: e.target.value })}
                    className="w-full p-2 border border-slate-200 rounded-lg"
                    min="1"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-slate-700 mb-1">
                    Price ($)
                  </label>
                  <input
                    type="number"
                    value={service.price.toString()}
                    onChange={(e) => updateService(index, { price: e.target.value })}
                    className="w-full p-2 border border-slate-200 rounded-lg"
                    min="0"
                    step="0.01"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-slate-700 mb-1">
                    Description
                  </label>
                  <input
                    type="text"
                    value={service.description || ''}
                    onChange={(e) => updateService(index, { description: e.target.value })}
                    className="w-full p-2 border border-slate-200 rounded-lg"
                  />
                </div>
              </div>
              <button
                type="button"
                onClick={() => removeService(index)}
                className="ml-4 text-red-500 hover:text-red-700 transition-colors"
              >
                <Trash2 className="w-5 h-5" />
              </button>
            </div>
          </div>
        ))}

        {error && (
          <p className="text-red-500 text-sm">{error}</p>
        )}

        {success && (
          <p className="text-green-500 text-sm">{success}</p>
        )}

        <button
          type="submit"
          disabled={loading}
          className="flex items-center justify-center w-full md:w-auto px-6 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors disabled:opacity-50"
        >
          {loading ? (
            'Saving...'
          ) : (
            <>
              <Save className="w-4 h-4 mr-2" />
              Save Changes
            </>
          )}
        </button>
      </form>
    </div>
  );
}