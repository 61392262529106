import { useState, useEffect } from 'react';
import { User } from 'firebase/auth';
import { doc, getDoc, setDoc, serverTimestamp } from 'firebase/firestore';
import { auth, db } from '../lib/firebase';
import { CustomerUser, UserRole } from '../types/user';

export function useUser() {
  const [user, setUser] = useState<CustomerUser | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    return auth.onAuthStateChanged(async (firebaseUser) => {
      try {
        if (firebaseUser) {
          const userData = await getUserData(firebaseUser.uid);
          setUser(userData);
        } else {
          setUser(null);
        }
      } catch (err) {
        setError(err instanceof Error ? err : new Error('Unknown error'));
      } finally {
        setLoading(false);
      }
    });
  }, []);

  const getUserData = async (uid: string): Promise<CustomerUser | null> => {
    const userDoc = await getDoc(doc(db, 'users', uid));
    return userDoc.exists() ? (userDoc.data() as CustomerUser) : null;
  };

  const createUser = async (
    firebaseUser: User,
    role: UserRole,
    userData: Partial<CustomerUser>
  ) => {
    try {
      const timestamp = serverTimestamp();
      
      // Create the base user document
      const userRef = doc(db, 'users', firebaseUser.uid);
      const customerData: CustomerUser = {
        uid: firebaseUser.uid,
        email: firebaseUser.email!,
        role: role as 'customer',
        firstName: userData.firstName || '',
        lastName: userData.lastName || '',
        createdAt: timestamp,
        updatedAt: timestamp,
        preferences: {
          notifications: {
            email: true,
            sms: false
          },
          preferredBusinesses: []
        }
      };

      // Set the user document with merge option to handle existing data
      await setDoc(userRef, customerData, { merge: true });
      
      // If this is a business user, create their business profile
      if (role === 'business') {
        const businessRef = doc(db, 'businesses', firebaseUser.uid);
        await setDoc(businessRef, {
          id: firebaseUser.uid,
          name: `${userData.firstName}'s Business`,
          ownerId: firebaseUser.uid,
          contact: {
            email: firebaseUser.email,
            phone: '',
            address: {
              street: '',
              city: '',
              state: '',
              zip: '',
              country: ''
            }
          },
          settings: {
            timezone: 'UTC',
            workingHours: {
              monday: { start: '09:00', end: '17:00' },
              tuesday: { start: '09:00', end: '17:00' },
              wednesday: { start: '09:00', end: '17:00' },
              thursday: { start: '09:00', end: '17:00' },
              friday: { start: '09:00', end: '17:00' },
              saturday: { start: '09:00', end: '17:00' },
              sunday: { start: '09:00', end: '17:00' }
            },
            services: []
          },
          subscription: {
            plan: 'free',
            status: 'active',
            validUntil: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000) // 30 days trial
          },
          createdAt: timestamp,
          updatedAt: timestamp
        }, { merge: true });
      }

      return { success: true, user: customerData };
    } catch (error) {
      console.error('Error creating user:', error);
      throw error;
    }
  };

  return {
    user,
    loading,
    error,
    createUser,
    getUserData
  };
}