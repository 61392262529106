import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import HomePage from './pages/HomePage';
import BusinessPortal from './components/business/BusinessPortal';
import CustomerPortal from './components/customer/CustomerPortal';
import BusinessCustomerPortal from './components/customer/BusinessCustomerPortal';
import ProtectedRoute from './components/ProtectedRoute';
import NotFound from './components/NotFound';

export default function App() {
  return (
    <Router>
      <div className="min-h-screen bg-gradient-to-b from-slate-50 to-white">
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/business" element={
            <ProtectedRoute requiredRole="business">
              <BusinessPortal />
            </ProtectedRoute>
          } />
          <Route path="/customer" element={
            <ProtectedRoute requiredRole="customer">
              <CustomerPortal />
            </ProtectedRoute>
          } />
          <Route path="/b/:businessId" element={<BusinessCustomerPortal />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </Router>
  );
}