import React from 'react';
import { Building2, Globe2, Users, Shield } from 'lucide-react';

export default function About() {
  return (
    <div id="about" className="py-24 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-bold text-slate-900">About AppointFlex</h2>
          <p className="mt-4 text-lg text-slate-600 max-w-3xl mx-auto">
            A brand of Global Voice Connect Solutions, delivering innovative booking solutions worldwide
          </p>
        </div>

        <div className="mt-20 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          <div className="text-center">
            <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-indigo-100 mb-6">
              <Building2 className="w-8 h-8 text-indigo-600" />
            </div>
            <h3 className="text-lg font-semibold text-slate-900 mb-2">Established Excellence</h3>
            <p className="text-slate-600">
              Part of Global Voice Connect Solutions, a leader in business communication solutions
            </p>
          </div>

          <div className="text-center">
            <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-indigo-100 mb-6">
              <Globe2 className="w-8 h-8 text-indigo-600" />
            </div>
            <h3 className="text-lg font-semibold text-slate-900 mb-2">Global Reach</h3>
            <p className="text-slate-600">
              Serving businesses across continents with localized booking solutions
            </p>
          </div>

          <div className="text-center">
            <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-indigo-100 mb-6">
              <Users className="w-8 h-8 text-indigo-600" />
            </div>
            <h3 className="text-lg font-semibold text-slate-900 mb-2">Customer Focus</h3>
            <p className="text-slate-600">
              Dedicated to enhancing the booking experience for businesses and their clients
            </p>
          </div>

          <div className="text-center">
            <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-indigo-100 mb-6">
              <Shield className="w-8 h-8 text-indigo-600" />
            </div>
            <h3 className="text-lg font-semibold text-slate-900 mb-2">Trusted Security</h3>
            <p className="text-slate-600">
              Enterprise-grade security protecting your business and customer data
            </p>
          </div>
        </div>

        <div className="mt-20 bg-indigo-50 rounded-2xl p-8 md:p-12">
          <div className="max-w-3xl mx-auto text-center">
            <h3 className="text-2xl font-bold text-slate-900 mb-4">Our Mission</h3>
            <p className="text-lg text-slate-600">
              At AppointFlex, we're committed to revolutionizing how businesses manage their appointments. 
              As part of Global Voice Connect Solutions, we leverage decades of experience in business 
              communication to deliver a booking platform that truly understands and meets the needs of 
              modern service-based businesses.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}