import React, { useEffect, useState, useRef } from 'react';
import { loadScript } from '@paypal/paypal-js';
import { useAuth } from '../../hooks/useAuth';
import { Loader, AlertCircle } from 'lucide-react';

interface PayPalButtonsProps {
  planId: string;
  onSuccess: (subscriptionId: string) => void;
  onError: (error: Error) => void;
}

export default function PayPalButtons({ planId, onSuccess, onError }: PayPalButtonsProps) {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [scriptError, setScriptError] = useState<string | null>(null);
  const buttonContainerRef = useRef<HTMLDivElement>(null);
  const paypalButtonsRef = useRef<any>(null);

  useEffect(() => {
    let isSubscribed = true;
    let paypalInstance: any = null;

    const initializePayPal = async () => {
      try {
        if (!buttonContainerRef.current) return;

        // Load PayPal SDK with specific configuration
        paypalInstance = await loadScript({
          'client-id': import.meta.env.VITE_PAYPAL_CLIENT_ID,
          vault: true,
          intent: 'subscription',
          'enable-funding': 'card,paylater',
          'data-namespace': 'paypal_sdk',
          'data-page-type': 'checkout',
          currency: 'USD'
        });

        if (!paypalInstance || !isSubscribed) {
          throw new Error('Failed to load PayPal SDK');
        }

        // Clear any existing buttons
        if (buttonContainerRef.current) {
          buttonContainerRef.current.innerHTML = '';
        }

        // Create PayPal buttons with specific configuration
        const buttons = paypalInstance.Buttons({
          style: {
            shape: 'rect',
            color: 'blue',
            layout: 'vertical',
            label: 'subscribe'
          },
          createSubscription: async (data: any, actions: any) => {
            try {
              return await actions.subscription.create({
                plan_id: planId,
                custom_id: user?.uid,
                application_context: {
                  shipping_preference: 'NO_SHIPPING',
                  user_action: 'SUBSCRIBE_NOW',
                  brand_name: 'AppointFlex',
                  locale: 'en-US',
                  landing_page: 'LOGIN',
                  user_experience: 'MINIMAL'
                }
              });
            } catch (error) {
              console.error('Error creating subscription:', error);
              throw error;
            }
          },
          onApprove: async (data: any) => {
            try {
              console.log('Subscription approved:', data);
              onSuccess(data.subscriptionID);
            } catch (error) {
              console.error('Error processing approval:', error);
              onError(error instanceof Error ? error : new Error('Subscription processing failed'));
            }
          },
          onError: (err: Error) => {
            console.error('PayPal Error:', err);
            onError(err);
          },
          onCancel: () => {
            console.log('Subscription cancelled by user');
          }
        });

        // Store buttons reference for cleanup
        paypalButtonsRef.current = buttons;

        // Render buttons if component is still mounted
        if (isSubscribed && buttonContainerRef.current) {
          await buttons.render(buttonContainerRef.current);
        }

      } catch (error) {
        console.error('PayPal initialization error:', error);
        if (isSubscribed) {
          setScriptError(error instanceof Error ? error.message : 'Failed to load PayPal');
        }
      } finally {
        if (isSubscribed) {
          setLoading(false);
        }
      }
    };

    initializePayPal();

    // Cleanup function
    return () => {
      isSubscribed = false;
      if (paypalButtonsRef.current) {
        try {
          paypalButtonsRef.current.close();
        } catch (err) {
          console.warn('Error cleaning up PayPal buttons:', err);
        }
      }
      if (paypalInstance) {
        try {
          paypalInstance.destroy();
        } catch (err) {
          console.warn('Error destroying PayPal instance:', err);
        }
      }
    };
  }, [planId, user, onSuccess, onError]);

  if (scriptError) {
    return (
      <div className="p-4 bg-red-50 border border-red-200 rounded-lg text-red-800 flex items-center gap-2">
        <AlertCircle className="w-5 h-5" />
        <p className="text-sm">Failed to load PayPal: {scriptError}</p>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="flex items-center justify-center p-4">
        <Loader className="w-6 h-6 text-indigo-600 animate-spin mr-2" />
        <span className="text-slate-600">Loading PayPal...</span>
      </div>
    );
  }

  return (
    <div 
      ref={buttonContainerRef}
      className="min-h-[150px] flex items-center justify-center"
    />
  );
}