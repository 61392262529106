import React, { useState } from 'react';
import { Copy, CheckCircle2, Edit2, Save, X } from 'lucide-react';
import type { Business } from '../../types/business';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';

interface BusinessProfileProps {
  business: Business;
}

export default function BusinessProfile({ business }: BusinessProfileProps) {
  const [copied, setCopied] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [contactInfo, setContactInfo] = useState({
    email: business.contact.email,
    phone: business.contact.phone,
    address: {
      street: business.contact.address.street,
      city: business.contact.address.city,
      state: business.contact.address.state,
      zip: business.contact.address.zip,
      country: business.contact.address.country
    }
  });

  const customerPortalUrl = `${window.location.origin}/b/${business.id}`;

  const copyLink = async () => {
    try {
      await navigator.clipboard.writeText(customerPortalUrl);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  const handleSave = async () => {
    setLoading(true);
    setError(null);

    try {
      const businessRef = doc(db, 'businesses', business.id);
      await updateDoc(businessRef, {
        'contact.email': contactInfo.email,
        'contact.phone': contactInfo.phone,
        'contact.address': contactInfo.address
      });
      setIsEditing(false);
    } catch (err) {
      console.error('Error updating contact info:', err);
      setError('Failed to update contact information');
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setContactInfo({
      email: business.contact.email,
      phone: business.contact.phone,
      address: { ...business.contact.address }
    });
    setIsEditing(false);
    setError(null);
  };

  return (
    <div className="bg-white rounded-xl shadow-lg p-6 mb-8">
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4">
        <div className="flex-1">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-2xl font-semibold text-slate-900">Business Profile</h2>
            <button
              onClick={() => setIsEditing(!isEditing)}
              className={`p-2 rounded-lg transition-colors ${
                isEditing 
                  ? 'bg-red-100 text-red-600 hover:bg-red-200'
                  : 'bg-slate-100 text-slate-600 hover:bg-slate-200'
              }`}
            >
              {isEditing ? <X className="w-5 h-5" /> : <Edit2 className="w-5 h-5" />}
            </button>
          </div>

          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-slate-700 mb-1">
                Business Name
              </label>
              <p className="text-slate-900">{business.name}</p>
            </div>

            <div>
              <label className="block text-sm font-medium text-slate-700 mb-1">
                Contact Email
              </label>
              {isEditing ? (
                <input
                  type="email"
                  value={contactInfo.email}
                  onChange={(e) => setContactInfo({ ...contactInfo, email: e.target.value })}
                  className="w-full p-2 border border-slate-200 rounded-lg"
                />
              ) : (
                <p className="text-slate-900">{contactInfo.email}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-slate-700 mb-1">
                Contact Phone
              </label>
              {isEditing ? (
                <input
                  type="tel"
                  value={contactInfo.phone}
                  onChange={(e) => setContactInfo({ ...contactInfo, phone: e.target.value })}
                  className="w-full p-2 border border-slate-200 rounded-lg"
                />
              ) : (
                <p className="text-slate-900">{contactInfo.phone}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-slate-700 mb-1">
                Address
              </label>
              {isEditing ? (
                <div className="space-y-2">
                  <input
                    type="text"
                    placeholder="Street"
                    value={contactInfo.address.street}
                    onChange={(e) => setContactInfo({
                      ...contactInfo,
                      address: { ...contactInfo.address, street: e.target.value }
                    })}
                    className="w-full p-2 border border-slate-200 rounded-lg"
                  />
                  <div className="grid grid-cols-2 gap-2">
                    <input
                      type="text"
                      placeholder="City"
                      value={contactInfo.address.city}
                      onChange={(e) => setContactInfo({
                        ...contactInfo,
                        address: { ...contactInfo.address, city: e.target.value }
                      })}
                      className="w-full p-2 border border-slate-200 rounded-lg"
                    />
                    <input
                      type="text"
                      placeholder="State"
                      value={contactInfo.address.state}
                      onChange={(e) => setContactInfo({
                        ...contactInfo,
                        address: { ...contactInfo.address, state: e.target.value }
                      })}
                      className="w-full p-2 border border-slate-200 rounded-lg"
                    />
                  </div>
                  <div className="grid grid-cols-2 gap-2">
                    <input
                      type="text"
                      placeholder="ZIP Code"
                      value={contactInfo.address.zip}
                      onChange={(e) => setContactInfo({
                        ...contactInfo,
                        address: { ...contactInfo.address, zip: e.target.value }
                      })}
                      className="w-full p-2 border border-slate-200 rounded-lg"
                    />
                    <input
                      type="text"
                      placeholder="Country"
                      value={contactInfo.address.country}
                      onChange={(e) => setContactInfo({
                        ...contactInfo,
                        address: { ...contactInfo.address, country: e.target.value }
                      })}
                      className="w-full p-2 border border-slate-200 rounded-lg"
                    />
                  </div>
                </div>
              ) : (
                <p className="text-slate-900">
                  {contactInfo.address.street}<br />
                  {contactInfo.address.city}, {contactInfo.address.state} {contactInfo.address.zip}<br />
                  {contactInfo.address.country}
                </p>
              )}
            </div>

            {error && (
              <p className="text-red-500 text-sm">{error}</p>
            )}

            {isEditing && (
              <div className="flex justify-end space-x-2 pt-4">
                <button
                  onClick={handleCancel}
                  className="px-4 py-2 text-slate-600 hover:text-slate-900"
                >
                  Cancel
                </button>
                <button
                  onClick={handleSave}
                  disabled={loading}
                  className="flex items-center px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors disabled:opacity-50"
                >
                  {loading ? 'Saving...' : (
                    <>
                      <Save className="w-4 h-4 mr-2" />
                      Save Changes
                    </>
                  )}
                </button>
              </div>
            )}
          </div>
        </div>

        <div className="w-full md:w-auto">
          <div className="bg-slate-50 p-4 rounded-lg">
            <h3 className="text-sm font-medium text-slate-700 mb-2">Booking Page URL</h3>
            <div className="flex items-center gap-2">
              <input
                type="text"
                value={customerPortalUrl}
                readOnly
                className="text-sm bg-white p-2 rounded border border-slate-200 flex-grow"
              />
              <button
                onClick={copyLink}
                className="p-2 text-slate-400 hover:text-slate-600 transition-colors"
                title="Copy URL"
              >
                {copied ? (
                  <CheckCircle2 className="w-5 h-5 text-green-600" />
                ) : (
                  <Copy className="w-5 h-5" />
                )}
              </button>
            </div>
            <p className="text-xs text-slate-500 mt-2">
              Share this URL with your customers to let them book appointments
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}