import React, { useState, useEffect } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { Business, Employee } from '../../types/business';
import BookingForm from './BookingForm';
import { MapPin, Phone, Mail, Clock, Loader } from 'lucide-react';
import { useAuth } from '../../hooks/useAuth';

export default function BusinessCustomerPortal() {
  const { businessId } = useParams<{ businessId: string }>();
  const { user } = useAuth();
  const [business, setBusiness] = useState<Business | null>(null);
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [monthlyBookings, setMonthlyBookings] = useState<number>(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchBusinessData = async () => {
      if (!businessId) {
        setError('Business ID is required');
        setLoading(false);
        return;
      }

      try {
        // Fetch business document
        const businessDoc = await getDoc(doc(db, 'businesses', businessId));
        if (!businessDoc.exists()) {
          setError('Business not found');
          return;
        }

        const businessData = businessDoc.data();
        setBusiness({
          ...businessData,
          id: businessDoc.id,
          createdAt: businessData.createdAt?.toDate() || new Date(),
          updatedAt: businessData.updatedAt?.toDate() || new Date(),
          subscription: {
            ...businessData.subscription,
            validUntil: businessData.subscription.validUntil?.toDate() || new Date()
          }
        } as Business);

        // Fetch employees
        const employeesSnapshot = await getDocs(
          collection(db, 'businesses', businessId, 'employees')
        );
        const employeesData = employeesSnapshot.docs.map(doc => ({
          ...doc.data(),
          id: doc.id,
          createdAt: doc.data().createdAt?.toDate() || new Date(),
          updatedAt: doc.data().updatedAt?.toDate() || new Date()
        })) as Employee[];
        setEmployees(employeesData);

        // Fetch monthly bookings if user is logged in
        if (user) {
          const startOfMonth = new Date();
          startOfMonth.setDate(1);
          startOfMonth.setHours(0, 0, 0, 0);

          const bookingsQuery = query(
            collection(db, 'businesses', businessId, 'appointments'),
            where('customerId', '==', user.uid),
            where('date', '>=', startOfMonth)
          );
          const bookingsSnapshot = await getDocs(bookingsQuery);
          setMonthlyBookings(bookingsSnapshot.size);
        }
      } catch (err) {
        console.error('Error fetching business:', err);
        setError('Failed to load business information');
      } finally {
        setLoading(false);
      }
    };

    fetchBusinessData();
  }, [businessId, user]);

  if (loading) {
    return (
      <div className="min-h-screen pt-20 flex items-center justify-center">
        <Loader className="w-8 h-8 text-indigo-600 animate-spin" />
      </div>
    );
  }

  if (error || !business) {
    return (
      <div className="min-h-screen pt-20 flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-slate-900 mb-2">Error</h2>
          <p className="text-slate-600">{error || 'Business not found'}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="pt-20 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="grid md:grid-cols-2 gap-12">
          {/* Business Information */}
          <div>
            <h1 className="text-3xl font-bold text-slate-900 mb-6">{business.name}</h1>
            
            <div className="bg-white rounded-lg shadow-md p-6 space-y-4">
              <div className="flex items-start gap-3">
                <MapPin className="w-5 h-5 text-slate-400 mt-1" />
                <div>
                  <h3 className="font-medium text-slate-900">Address</h3>
                  <p className="text-slate-600">
                    {business.contact.address.street}<br />
                    {business.contact.address.city}, {business.contact.address.state} {business.contact.address.zip}
                  </p>
                </div>
              </div>

              <div className="flex items-center gap-3">
                <Phone className="w-5 h-5 text-slate-400" />
                <div>
                  <h3 className="font-medium text-slate-900">Phone</h3>
                  <p className="text-slate-600">{business.contact.phone}</p>
                </div>
              </div>

              <div className="flex items-center gap-3">
                <Mail className="w-5 h-5 text-slate-400" />
                <div>
                  <h3 className="font-medium text-slate-900">Email</h3>
                  <p className="text-slate-600">{business.contact.email}</p>
                </div>
              </div>

              <div className="flex items-start gap-3">
                <Clock className="w-5 h-5 text-slate-400 mt-1" />
                <div>
                  <h3 className="font-medium text-slate-900">Working Hours</h3>
                  <div className="grid grid-cols-2 gap-2 text-sm text-slate-600">
                    {Object.entries(business.settings.workingHours).map(([day, hours]) => (
                      <div key={day}>
                        <span className="capitalize">{day}: </span>
                        {hours.start} - {hours.end}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            {/* Services List */}
            <div className="mt-8">
              <h2 className="text-xl font-semibold text-slate-900 mb-4">Our Services</h2>
              <div className="bg-white rounded-lg shadow-md">
                {business.settings.services.map((service) => (
                  <div
                    key={service.id}
                    className="p-4 border-b last:border-b-0 border-slate-100"
                  >
                    <div className="flex justify-between items-start">
                      <div>
                        <h3 className="font-medium text-slate-900">{service.name}</h3>
                        {service.description && (
                          <p className="text-sm text-slate-600 mt-1">{service.description}</p>
                        )}
                      </div>
                      <div className="text-right">
                        <span className="font-medium text-slate-900">
                          ${service.price.toFixed(2)}
                        </span>
                        <p className="text-sm text-slate-500">{service.duration} min</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Booking Form */}
          <div className="md:sticky md:top-24 self-start">
            <BookingForm 
              business={business} 
              employees={employees}
              monthlyBookings={monthlyBookings}
            />
          </div>
        </div>
      </div>
    </div>
  );
}