import { useState, useEffect } from 'react';
import { 
  collection,
  query,
  where,
  getDocs,
  addDoc,
  doc,
  Timestamp,
  serverTimestamp,
  getDoc
} from 'firebase/firestore';
import { db, auth } from '../lib/firebase';

export interface Booking {
  id?: string;
  userId: string;
  businessId: string;
  service: string;
  serviceId: string;
  date: Date;
  time: string;
  employeeId?: string;
  status: 'pending' | 'confirmed' | 'cancelled';
  duration: number;
  price: number;
  createdAt?: Date;
}

export function useBookings(userId: string | undefined) {
  const [bookings, setBookings] = useState<Booking[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchBookings = async () => {
      if (!userId || !auth.currentUser) {
        setLoading(false);
        return;
      }

      try {
        const allBookings: Booking[] = [];
        const businessesSnapshot = await getDocs(collection(db, 'businesses'));
        
        for (const businessDoc of businessesSnapshot.docs) {
          const appointmentsQuery = query(
            collection(db, 'businesses', businessDoc.id, 'appointments'),
            where('customerId', '==', userId)
          );

          const appointmentsSnapshot = await getDocs(appointmentsQuery);
          const businessBookings = appointmentsSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
            date: doc.data().date.toDate(),
            createdAt: doc.data().createdAt?.toDate()
          })) as Booking[];

          allBookings.push(...businessBookings);
        }

        setBookings(allBookings);
      } catch (err) {
        console.error('Error fetching bookings:', err);
        setError(err instanceof Error ? err : new Error('Failed to fetch bookings'));
      } finally {
        setLoading(false);
      }
    };

    fetchBookings();
  }, [userId]);

  const createBooking = async (bookingData: Omit<Booking, 'id' | 'createdAt'>) => {
    if (!auth.currentUser) {
      return { success: false, error: new Error('User not authenticated') };
    }

    try {
      // Add to business-specific appointments collection
      const appointmentsRef = collection(
        db,
        'businesses',
        bookingData.businessId,
        'appointments'
      );
      
      const docRef = await addDoc(appointmentsRef, {
        ...bookingData,
        customerId: auth.currentUser.uid,
        date: Timestamp.fromDate(bookingData.date),
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      });
      
      const newBooking: Booking = {
        id: docRef.id,
        ...bookingData,
        createdAt: new Date()
      };
      
      setBookings(prev => [...prev, newBooking]);
      return { success: true, id: docRef.id };
    } catch (error) {
      console.error('Error creating booking:', error);
      return { success: false, error };
    }
  };

  return {
    bookings,
    loading,
    error,
    createBooking
  };
}