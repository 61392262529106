import React, { useState } from 'react';
import { Calendar, ArrowRight } from 'lucide-react';
import AuthModal from './AuthModal';

export default function Hero() {
  const [showAuthModal, setShowAuthModal] = useState(false);

  return (
    <>
      <div className="pt-24 pb-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <div className="text-center">
            <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold text-slate-900 tracking-tight">
              Smart Booking
              <span className="text-indigo-600"> Made Simple</span>
            </h1>
            <p className="mt-6 text-xl text-slate-600 max-w-3xl mx-auto">
              Transform your business with AppointFlex's intelligent booking system. Perfect for salons, spas, and service-based businesses.
            </p>
            <div className="mt-10 flex justify-center gap-4">
              <button
                onClick={() => setShowAuthModal(true)}
                className="group bg-indigo-600 text-white px-6 py-3 rounded-lg hover:bg-indigo-700 transition-all flex items-center gap-2"
              >
                Start Free Trial
                <ArrowRight className="w-4 h-4 group-hover:translate-x-1 transition-transform" />
              </button>
              <button className="px-6 py-3 text-slate-700 hover:text-indigo-600 transition-colors">
                Watch Demo
              </button>
            </div>
            <div className="mt-16">
              <img 
                src="https://images.unsplash.com/photo-1556740758-90de374c12ad?auto=format&fit=crop&q=80&w=2000"
                alt="AppointFlex Dashboard"
                className="rounded-xl shadow-2xl shadow-indigo-100 border border-slate-200"
              />
            </div>
          </div>
        </div>
      </div>

      <AuthModal
        isOpen={showAuthModal}
        onClose={() => setShowAuthModal(false)}
        mode="signup"
      />
    </>
  );
}