import { useState, useEffect } from 'react';
import { 
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  updateDoc,
  setDoc,
  serverTimestamp,
  Timestamp,
  addDoc,
  deleteDoc
} from 'firebase/firestore';
import { db } from '../lib/firebase';
import type { Business, Employee, BusinessService } from '../types/business';

export function useBusinessManagement(businessId: string) {
  const [business, setBusiness] = useState<Business | null>(null);
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchBusinessData = async () => {
      if (!businessId) {
        setLoading(false);
        return;
      }

      try {
        // Fetch business document
        const businessDoc = await getDoc(doc(db, 'businesses', businessId));
        if (businessDoc.exists()) {
          const data = businessDoc.data();
          setBusiness({
            ...data,
            id: businessDoc.id,
            createdAt: data.createdAt?.toDate() || new Date(),
            updatedAt: data.updatedAt?.toDate() || new Date(),
            subscription: {
              ...data.subscription,
              validUntil: data.subscription.validUntil?.toDate() || new Date()
            }
          } as Business);

          // Fetch employees
          await fetchEmployees();
        }
      } catch (err) {
        console.error('Error fetching business data:', err);
        setError(err instanceof Error ? err : new Error('Failed to fetch business data'));
      } finally {
        setLoading(false);
      }
    };

    fetchBusinessData();
  }, [businessId]);

  const fetchEmployees = async () => {
    try {
      // Fetch employees
      const employeesSnapshot = await getDocs(collection(db, 'businesses', businessId, 'employees'));
      
      // Fetch service assignments for all employees
      const serviceAssignmentsSnapshot = await getDocs(
        collection(db, 'businesses', businessId, 'serviceAssignments')
      );
      
      const serviceAssignments = new Map<string, string[]>();
      serviceAssignmentsSnapshot.docs.forEach(doc => {
        const data = doc.data();
        const employeeId = data.employeeId;
        const serviceId = data.serviceId;
        
        if (!serviceAssignments.has(employeeId)) {
          serviceAssignments.set(employeeId, []);
        }
        serviceAssignments.get(employeeId)?.push(serviceId);
      });

      const employeesData = employeesSnapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
        services: serviceAssignments.get(doc.id) || [],
        createdAt: doc.data().createdAt?.toDate() || new Date(),
        updatedAt: doc.data().updatedAt?.toDate() || new Date()
      })) as Employee[];

      setEmployees(employeesData);
    } catch (err) {
      console.error('Error fetching employees:', err);
      throw err;
    }
  };

  const addEmployee = async (employeeData: Omit<Employee, 'id' | 'createdAt' | 'updatedAt'>) => {
    try {
      const timestamp = serverTimestamp();
      
      // Create employee document
      const employeeRef = collection(db, 'businesses', businessId, 'employees');
      const employeeDoc = await addDoc(employeeRef, {
        ...employeeData,
        createdAt: timestamp,
        updatedAt: timestamp
      });

      // Create service assignments
      const serviceAssignmentsRef = collection(db, 'businesses', businessId, 'serviceAssignments');
      await Promise.all(employeeData.services.map(serviceId => 
        addDoc(serviceAssignmentsRef, {
          employeeId: employeeDoc.id,
          serviceId,
          createdAt: timestamp
        })
      ));

      await fetchEmployees();
      return { success: true };
    } catch (err) {
      console.error('Error adding employee:', err);
      return { success: false, error: err };
    }
  };

  const updateEmployee = async (employeeId: string, updates: Partial<Employee>) => {
    try {
      const timestamp = serverTimestamp();
      
      // Update employee document
      const employeeRef = doc(db, 'businesses', businessId, 'employees', employeeId);
      await updateDoc(employeeRef, {
        ...updates,
        updatedAt: timestamp
      });

      // If services were updated, update service assignments
      if (updates.services) {
        const serviceAssignmentsRef = collection(db, 'businesses', businessId, 'serviceAssignments');
        
        // Delete existing assignments
        const existingAssignmentsSnapshot = await getDocs(
          query(serviceAssignmentsRef, where('employeeId', '==', employeeId))
        );
        
        await Promise.all(existingAssignmentsSnapshot.docs.map(doc => deleteDoc(doc.ref)));

        // Create new assignments
        await Promise.all(updates.services.map(serviceId => 
          addDoc(serviceAssignmentsRef, {
            employeeId,
            serviceId,
            createdAt: timestamp
          })
        ));
      }

      await fetchEmployees();
      return { success: true };
    } catch (err) {
      console.error('Error updating employee:', err);
      return { success: false, error: err };
    }
  };

  const checkEmployeeAvailability = async (
    employeeId: string,
    date: Date,
    duration: number
  ): Promise<boolean> => {
    try {
      const startTime = new Date(date);
      const endTime = new Date(date.getTime() + duration * 60000);
      
      // Get employee's schedule
      const employee = employees.find(e => e.id === employeeId);
      if (!employee) return false;

      const dayOfWeek = date.toLocaleDateString('en-US', { weekday: 'long' }).toLowerCase();
      const schedule = employee.schedule[dayOfWeek];

      // Check if day is enabled and within working hours
      if (!schedule.enabled) return false;

      const [scheduleStartHour, scheduleStartMinute] = schedule.start.split(':').map(Number);
      const [scheduleEndHour, scheduleEndMinute] = schedule.end.split(':').map(Number);
      
      const scheduleStart = new Date(date);
      scheduleStart.setHours(scheduleStartHour, scheduleStartMinute, 0, 0);
      
      const scheduleEnd = new Date(date);
      scheduleEnd.setHours(scheduleEndHour, scheduleEndMinute, 0, 0);

      if (startTime < scheduleStart || endTime > scheduleEnd) return false;

      // Check for existing appointments
      const appointmentsRef = collection(db, 'businesses', businessId, 'appointments');
      const q = query(
        appointmentsRef,
        where('employeeId', '==', employeeId),
        where('date', '>=', startTime),
        where('date', '<=', endTime),
        where('status', '!=', 'cancelled')
      );

      const snapshot = await getDocs(q);
      return snapshot.empty;
    } catch (err) {
      console.error('Error checking employee availability:', err);
      return false;
    }
  };

  return {
    business,
    employees,
    loading,
    error,
    addEmployee,
    updateEmployee,
    fetchEmployees,
    checkEmployeeAvailability
  };
}