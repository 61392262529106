import React, { useState } from 'react';
import { Calendar, Menu, X } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import AuthModal from './AuthModal';
import { useAuth } from '../hooks/useAuth';

export default function Navbar() {
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [authMode, setAuthMode] = useState<'signin' | 'signup'>('signin');
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const { user, userRole, signOut } = useAuth();
  const navigate = useNavigate();

  const handleAuthClick = (mode: 'signin' | 'signup') => {
    setAuthMode(mode);
    setShowAuthModal(true);
  };

  const handleSignOut = async () => {
    await signOut();
    navigate('/');
  };

  const scrollToSection = (sectionId: string) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    setShowMobileMenu(false);
  };

  return (
    <>
      <nav className="fixed w-full bg-white/80 backdrop-blur-md z-50 border-b border-slate-100">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16 items-center">
            <Link to="/" className="flex items-center space-x-2">
              <Calendar className="h-6 w-6 text-indigo-600" />
              <span className="text-xl font-bold text-slate-800">AppointFlex</span>
            </Link>

            {/* Desktop Navigation */}
            <div className="hidden md:flex items-center space-x-8">
              <button 
                onClick={() => scrollToSection('features')}
                className="text-slate-600 hover:text-indigo-600 transition-colors"
              >
                Features
              </button>
              <button
                onClick={() => scrollToSection('demo')}
                className="text-slate-600 hover:text-indigo-600 transition-colors"
              >
                Demo
              </button>
              <button
                onClick={() => scrollToSection('about')}
                className="text-slate-600 hover:text-indigo-600 transition-colors"
              >
                About
              </button>
              <button
                onClick={() => scrollToSection('pricing')}
                className="text-slate-600 hover:text-indigo-600 transition-colors"
              >
                Pricing
              </button>
              {user ? (
                <div className="flex items-center space-x-4">
                  {userRole === 'business' ? (
                    <Link 
                      to="/business"
                      className="text-slate-600 hover:text-indigo-600 transition-colors"
                    >
                      Business Portal
                    </Link>
                  ) : (
                    <Link 
                      to="/customer"
                      className="text-slate-600 hover:text-indigo-600 transition-colors"
                    >
                      My Appointments
                    </Link>
                  )}
                  <button
                    onClick={handleSignOut}
                    className="text-slate-600 hover:text-indigo-600 transition-colors"
                  >
                    Sign Out
                  </button>
                </div>
              ) : (
                <div className="flex items-center space-x-4">
                  <button
                    onClick={() => handleAuthClick('signin')}
                    className="text-slate-600 hover:text-indigo-600 transition-colors"
                  >
                    Sign In
                  </button>
                  <button
                    onClick={() => handleAuthClick('signup')}
                    className="bg-indigo-600 text-white px-4 py-2 rounded-lg hover:bg-indigo-700 transition-colors"
                  >
                    Get Started
                  </button>
                </div>
              )}
            </div>

            {/* Mobile Menu Button */}
            <button
              className="md:hidden"
              onClick={() => setShowMobileMenu(!showMobileMenu)}
            >
              {showMobileMenu ? (
                <X className="h-6 w-6 text-slate-600" />
              ) : (
                <Menu className="h-6 w-6 text-slate-600" />
              )}
            </button>
          </div>
        </div>

        {/* Mobile Menu */}
        {showMobileMenu && (
          <div className="md:hidden border-t border-slate-100">
            <div className="px-4 pt-2 pb-3 space-y-1">
              <button
                onClick={() => scrollToSection('features')}
                className="block w-full text-left px-3 py-2 text-slate-600 hover:text-indigo-600 transition-colors"
              >
                Features
              </button>
              <button
                onClick={() => scrollToSection('demo')}
                className="block w-full text-left px-3 py-2 text-slate-600 hover:text-indigo-600 transition-colors"
              >
                Demo
              </button>
              <button
                onClick={() => scrollToSection('about')}
                className="block w-full text-left px-3 py-2 text-slate-600 hover:text-indigo-600 transition-colors"
              >
                About
              </button>
              <button
                onClick={() => scrollToSection('pricing')}
                className="block w-full text-left px-3 py-2 text-slate-600 hover:text-indigo-600 transition-colors"
              >
                Pricing
              </button>
              {user ? (
                <>
                  {userRole === 'business' ? (
                    <Link
                      to="/business"
                      className="block px-3 py-2 text-slate-600 hover:text-indigo-600 transition-colors"
                    >
                      Business Portal
                    </Link>
                  ) : (
                    <Link
                      to="/customer"
                      className="block px-3 py-2 text-slate-600 hover:text-indigo-600 transition-colors"
                    >
                      My Appointments
                    </Link>
                  )}
                  <button
                    onClick={handleSignOut}
                    className="block w-full text-left px-3 py-2 text-slate-600 hover:text-indigo-600 transition-colors"
                  >
                    Sign Out
                  </button>
                </>
              ) : (
                <>
                  <button
                    onClick={() => handleAuthClick('signin')}
                    className="block w-full text-left px-3 py-2 text-slate-600 hover:text-indigo-600 transition-colors"
                  >
                    Sign In
                  </button>
                  <button
                    onClick={() => handleAuthClick('signup')}
                    className="block w-full text-left px-3 py-2 text-indigo-600 font-medium hover:text-indigo-700 transition-colors"
                  >
                    Get Started
                  </button>
                </>
              )}
            </div>
          </div>
        )}
      </nav>

      <AuthModal
        isOpen={showAuthModal}
        onClose={() => setShowAuthModal(false)}
        mode={authMode}
      />
    </>
  );
}