import React, { useState } from 'react';
import { X, AlertCircle } from 'lucide-react';
import PayPalButtons from './PayPalButtons';
import { useAuth } from '../../hooks/useAuth';

interface Plan {
  name: string;
  price: number;
  monthlyPayPalPlanId: string;
  features: string[];
}

interface CheckoutModalProps {
  isOpen: boolean;
  onClose: () => void;
  plan: Plan;
  isAnnual: boolean;
}

export default function CheckoutModal({ isOpen, onClose, plan, isAnnual }: CheckoutModalProps) {
  const [error, setError] = useState<string | null>(null);
  const { user } = useAuth();

  if (!isOpen) return null;

  const handlePayPalSuccess = (subscriptionId: string) => {
    console.log('Subscription successful:', subscriptionId);
    // Redirect to business portal with subscription ID
    window.location.href = `/business?subscription=${subscriptionId}`;
  };

  const handlePayPalError = (error: Error) => {
    console.error('PayPal error:', error);
    setError(error.message);
  };

  const price = isAnnual ? plan.price * 12 * 0.83 : plan.price; // 17% discount for annual

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-md w-full">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold text-slate-900">
            Subscribe to {plan.name}
          </h2>
          <button
            onClick={onClose}
            className="text-slate-400 hover:text-slate-600"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        <div className="mb-6">
          <div className="text-center mb-4">
            <p className="text-3xl font-bold text-slate-900">
              ${price.toFixed(2)}
            </p>
            <p className="text-slate-600">
              per {isAnnual ? 'year' : 'month'}
              {isAnnual && <span className="text-green-600 ml-2">(Save 17%)</span>}
            </p>
          </div>

          <div className="border-t border-b border-slate-200 py-4 mb-6">
            <h3 className="text-sm font-medium text-slate-700 mb-2">
              Included in {plan.name}:
            </h3>
            <ul className="space-y-2">
              {plan.features.map((feature, index) => (
                <li key={index} className="text-sm text-slate-600 flex items-center">
                  <span className="mr-2">✓</span>
                  {feature}
                </li>
              ))}
            </ul>
          </div>

          {error && (
            <div className="mb-4 p-3 bg-red-50 border border-red-200 rounded-lg text-red-800 flex items-center gap-2">
              <AlertCircle className="w-5 h-5" />
              <p>{error}</p>
            </div>
          )}

          <PayPalButtons
            planId={plan.monthlyPayPalPlanId}
            onSuccess={handlePayPalSuccess}
            onError={handlePayPalError}
          />
        </div>

        <p className="mt-4 text-sm text-slate-500 text-center">
          By proceeding, you agree to our Terms of Service and Privacy Policy
        </p>
      </div>
    </div>
  );
}