import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, Timestamp, updateDoc, doc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { format, startOfWeek, endOfWeek, eachDayOfInterval, isSameDay, addWeeks, subWeeks, isToday } from 'date-fns';
import { ChevronLeft, ChevronRight, Plus, Check, X, AlertCircle, Calendar as CalendarIcon } from 'lucide-react';
import type { Employee, BusinessService } from '../../types/business';
import NewAppointmentModal from './NewAppointmentModal';

interface Appointment {
  id: string;
  customerName: string;
  customerEmail: string;
  customerPhone: string;
  service: string;
  serviceId: string;
  employeeId: string;
  date: Date;
  duration: number;
  price: number;
  status: 'pending' | 'confirmed' | 'cancelled';
}

interface BusinessCalendarProps {
  businessId: string;
  employees: Employee[];
  services: BusinessService[];
  workingHours: Record<string, { start: string; end: string; enabled: boolean }>;
}

export default function BusinessCalendar({ businessId, employees, services, workingHours }: BusinessCalendarProps) {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedEmployee, setSelectedEmployee] = useState<string>('all');
  const [showNewAppointmentModal, setShowNewAppointmentModal] = useState(false);
  const [viewMode, setViewMode] = useState<'week' | 'day'>('week');
  const [selectedDay, setSelectedDay] = useState<Date>(new Date());

  const startDate = startOfWeek(currentDate, { weekStartsOn: 1 });
  const endDate = endOfWeek(currentDate, { weekStartsOn: 1 });
  const days = eachDayOfInterval({ start: startDate, end: endDate });

  useEffect(() => {
    fetchAppointments();
  }, [currentDate, businessId, selectedEmployee]);

  const fetchAppointments = async () => {
    try {
      setLoading(true);
      const appointmentsRef = collection(db, 'businesses', businessId, 'appointments');
      let q = query(
        appointmentsRef,
        where('date', '>=', Timestamp.fromDate(startDate)),
        where('date', '<=', Timestamp.fromDate(endDate))
      );

      if (selectedEmployee !== 'all') {
        q = query(q, where('employeeId', '==', selectedEmployee));
      }

      const snapshot = await getDocs(q);
      const appointmentsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        date: doc.data().date.toDate()
      })) as Appointment[];

      setAppointments(appointmentsData);
      setError(null);
    } catch (err) {
      console.error('Error fetching appointments:', err);
      setError('Failed to load appointments');
    } finally {
      setLoading(false);
    }
  };

  const handleStatusUpdate = async (appointmentId: string, status: 'confirmed' | 'cancelled') => {
    try {
      const appointmentRef = doc(db, 'businesses', businessId, 'appointments', appointmentId);
      await updateDoc(appointmentRef, {
        status,
        updatedAt: Timestamp.now()
      });
      await fetchAppointments();
    } catch (err) {
      console.error('Error updating appointment status:', err);
      setError('Failed to update appointment status');
    }
  };

  const navigateWeek = (direction: 'prev' | 'next') => {
    setCurrentDate(prev => 
      direction === 'prev' ? subWeeks(prev, 1) : addWeeks(prev, 1)
    );
  };

  const getEmployeeName = (employeeId: string) => {
    const employee = employees.find(e => e.id === employeeId);
    return employee ? employee.name : 'Unknown';
  };

  const getStatusColor = (status: Appointment['status']) => {
    switch (status) {
      case 'confirmed':
        return 'bg-green-50 border-green-200 text-green-700';
      case 'cancelled':
        return 'bg-red-50 border-red-200 text-red-700';
      default:
        return 'bg-yellow-50 border-yellow-200 text-yellow-700';
    }
  };

  const getAppointmentsByDay = (day: Date) => {
    return appointments
      .filter(apt => isSameDay(apt.date, day))
      .sort((a, b) => a.date.getTime() - b.date.getTime());
  };

  if (loading) {
    return (
      <div className="bg-white rounded-xl shadow-lg p-6">
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-xl shadow-lg p-6">
      <div className="flex justify-between items-center mb-6">
        <div className="flex items-center space-x-4">
          <h2 className="text-2xl font-semibold text-slate-900">Calendar</h2>
          <div className="flex items-center space-x-2">
            <button
              onClick={() => navigateWeek('prev')}
              className="p-1 text-slate-400 hover:text-slate-600"
            >
              <ChevronLeft className="w-5 h-5" />
            </button>
            <span className="text-slate-600">
              {format(startDate, 'MMM d')} - {format(endDate, 'MMM d, yyyy')}
            </span>
            <button
              onClick={() => navigateWeek('next')}
              className="p-1 text-slate-400 hover:text-slate-600"
            >
              <ChevronRight className="w-5 h-5" />
            </button>
          </div>
          <div className="flex space-x-2">
            <button
              onClick={() => setViewMode('week')}
              className={`px-3 py-1 rounded-lg ${
                viewMode === 'week' 
                  ? 'bg-indigo-600 text-white' 
                  : 'text-slate-600 hover:bg-slate-100'
              }`}
            >
              Week
            </button>
            <button
              onClick={() => setViewMode('day')}
              className={`px-3 py-1 rounded-lg ${
                viewMode === 'day' 
                  ? 'bg-indigo-600 text-white' 
                  : 'text-slate-600 hover:bg-slate-100'
              }`}
            >
              Day
            </button>
          </div>
        </div>

        <div className="flex items-center space-x-4">
          <select
            value={selectedEmployee}
            onChange={(e) => setSelectedEmployee(e.target.value)}
            className="p-2 border border-slate-200 rounded-lg"
          >
            <option value="all">All Staff</option>
            {employees.map((employee) => (
              <option key={employee.id} value={employee.id}>
                {employee.name}
              </option>
            ))}
          </select>
          <button
            onClick={() => setShowNewAppointmentModal(true)}
            className="flex items-center px-3 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
          >
            <Plus className="w-4 h-4 mr-2" />
            New Appointment
          </button>
        </div>
      </div>

      {error && (
        <div className="mb-4 p-3 bg-red-50 border border-red-200 rounded-lg text-red-800 flex items-center gap-2">
          <AlertCircle className="w-5 h-5" />
          <p>{error}</p>
        </div>
      )}

      {viewMode === 'week' ? (
        <div className="grid grid-cols-7 gap-4">
          {days.map((day) => (
            <div 
              key={day.toISOString()} 
              className={`min-h-[200px] ${
                isToday(day) ? 'bg-indigo-50/50 rounded-lg' : ''
              }`}
              onClick={() => {
                setSelectedDay(day);
                setViewMode('day');
              }}
            >
              <div className="text-center p-2 bg-slate-50 rounded-t-lg">
                <p className="text-sm font-medium text-slate-600">
                  {format(day, 'EEE')}
                </p>
                <p className={`text-lg ${
                  isToday(day) ? 'text-indigo-600 font-bold' : 'text-slate-900'
                }`}>
                  {format(day, 'd')}
                </p>
              </div>
              <div className="p-2 space-y-2">
                {getAppointmentsByDay(day).map(appointment => (
                  <div
                    key={appointment.id}
                    className={`p-2 rounded-lg text-sm border ${getStatusColor(appointment.status)}`}
                  >
                    <p className="font-medium">{format(appointment.date, 'h:mm a')}</p>
                    <p>{appointment.customerName}</p>
                    <p className="text-sm">{appointment.service}</p>
                    <p className="text-sm">with {getEmployeeName(appointment.employeeId)}</p>
                    <div className="mt-2 flex justify-end space-x-1">
                      {appointment.status === 'pending' && (
                        <>
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              handleStatusUpdate(appointment.id, 'confirmed');
                            }}
                            className="p-1 text-green-600 hover:text-green-800"
                            title="Confirm"
                          >
                            <Check className="w-4 h-4" />
                          </button>
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              handleStatusUpdate(appointment.id, 'cancelled');
                            }}
                            className="p-1 text-red-600 hover:text-red-800"
                            title="Cancel"
                          >
                            <X className="w-4 h-4" />
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="space-y-4">
          <div className="flex items-center justify-between bg-slate-50 p-4 rounded-lg">
            <h3 className="text-lg font-semibold text-slate-900">
              {format(selectedDay, 'EEEE, MMMM d, yyyy')}
            </h3>
            <button
              onClick={() => setViewMode('week')}
              className="text-slate-600 hover:text-slate-900"
            >
              <CalendarIcon className="w-5 h-5" />
            </button>
          </div>
          <div className="space-y-2">
            {getAppointmentsByDay(selectedDay).length > 0 ? (
              getAppointmentsByDay(selectedDay).map(appointment => (
                <div
                  key={appointment.id}
                  className={`p-4 rounded-lg border ${getStatusColor(appointment.status)}`}
                >
                  <div className="flex justify-between items-start">
                    <div>
                      <p className="font-medium text-lg">{format(appointment.date, 'h:mm a')}</p>
                      <p className="font-medium">{appointment.customerName}</p>
                      <p>{appointment.service}</p>
                      <p className="text-sm">with {getEmployeeName(appointment.employeeId)}</p>
                      <p className="text-sm mt-1">{appointment.customerPhone}</p>
                      <p className="text-sm">{appointment.customerEmail}</p>
                    </div>
                    {appointment.status === 'pending' && (
                      <div className="flex space-x-2">
                        <button
                          onClick={() => handleStatusUpdate(appointment.id, 'confirmed')}
                          className="p-2 bg-green-100 text-green-700 rounded-lg hover:bg-green-200"
                          title="Confirm"
                        >
                          <Check className="w-4 h-4" />
                        </button>
                        <button
                          onClick={() => handleStatusUpdate(appointment.id, 'cancelled')}
                          className="p-2 bg-red-100 text-red-700 rounded-lg hover:bg-red-200"
                          title="Cancel"
                        >
                          <X className="w-4 h-4" />
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <p className="text-center text-slate-600 py-8">
                No appointments scheduled for this day
              </p>
            )}
          </div>
        </div>
      )}

      <NewAppointmentModal
        isOpen={showNewAppointmentModal}
        onClose={() => setShowNewAppointmentModal(false)}
        businessId={businessId}
        employees={employees}
        services={services}
        onSuccess={fetchAppointments}
      />
    </div>
  );
}