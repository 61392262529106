import React from 'react';
import { Calendar, Clock, MessageSquare, CreditCard, Users, Bell } from 'lucide-react';

const features = [
  {
    icon: Calendar,
    title: 'Instant Online Booking',
    description: 'Real-time availability and instant confirmation for your clients.'
  },
  {
    icon: Bell,
    title: 'Smart Notifications',
    description: 'Automated reminders to reduce no-shows and keep clients informed.'
  },
  {
    icon: Users,
    title: 'Client Management',
    description: 'Detailed client profiles and history for personalized service.'
  },
  {
    icon: MessageSquare,
    title: 'Chatbot Integration',
    description: 'AI-powered booking assistant for 24/7 appointment scheduling.'
  },
  {
    icon: CreditCard,
    title: 'Secure Payments',
    description: 'Integrated payment processing for deposits and full payments.'
  },
  {
    icon: Clock,
    title: 'Time Management',
    description: 'Optimize your schedule with smart booking rules and buffer times.'
  }
];

export default function Features() {
  return (
    <div id="features" className="py-24 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-bold text-slate-900">Everything you need to grow your business</h2>
          <p className="mt-4 text-lg text-slate-600">
            Powerful features to streamline your booking process and delight your customers
          </p>
        </div>
        <div className="mt-20 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <div key={index} className="group p-6 bg-white rounded-xl border border-slate-100 hover:border-indigo-100 hover:shadow-lg hover:shadow-indigo-50 transition-all">
              <feature.icon className="w-10 h-10 text-indigo-600 mb-4 group-hover:scale-110 transition-transform" />
              <h3 className="text-xl font-semibold text-slate-900 mb-2">{feature.title}</h3>
              <p className="text-slate-600">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}